const getApofinderV2Html = () : string => '<div id="ia-apofinder-widget"></div>';

const getApofinderV1Html = () : string => '<div id="Apofinder"></div>'

const getManufacturerHtml = () : string => '<div id="ManufacturerWidget"></div>';

const getManufacturerShopHtml = () : string => '<div id="ManufacturerShopWidget"></div>';

const getNetdoctorHtml = () : string => '<div id="NetDoctor"></div>';

const getNetdoctorIndicatorHtml = () : string => '<div id="NetDoctorIndicator"></div>';

const getNetdoctorWithPharmacyHtml = () : string => '<div id="NetDoctorWithPharmacy"></div>';

const getProductSearchHtml = () : string => '<div id="ProductSearch"></div>';

const getProductWithCategoryHtml = () : string => '<div id="ProductWithCategory"></div>';

export {
  getApofinderV1Html,
  getApofinderV2Html,
  getManufacturerHtml,
  getManufacturerShopHtml,
  getNetdoctorHtml,
  getNetdoctorWithPharmacyHtml,
  getNetdoctorIndicatorHtml,
  getProductSearchHtml,
  getProductWithCategoryHtml
}
