
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElHeader,
  ElMain,
  ElContainer,
  ElRow,
  ElCol,
} from "element-plus";
import Navigation from "@/components/Navigation.vue";

@Options({
  components: {
    ElHeader,
    ElMain,
    ElContainer,
    ElRow,
    ElCol,
    Navigation,
  }
})
export default class PreviewSnippetIndexVue extends Vue {
  config = {};
  formData: { environment: string; widget: string; type: string; device: string, previewType: string } = {
    environment: "",
    type: "",
    device: "",
    widget: "",
    previewType: "",
  };

  widgetIframeUrls = {
    localhost: {
      urlPrefix: "https://ia.local",
    },
    development: {
      urlPrefix: "https://dev-widgets.ihreapotheken.de"
    },
    qa: {
      urlPrefix: "https://qa.ihreapotheken.de"
    },
    production: {
      urlPrefix: "https://ihreapotheken.de"
    },
  }

  created(): void {
    this.config = JSON.parse((this.$route.query.config as string).replaceAll("~", "#"));
    this.formData = JSON.parse(this.$route.query.formData as string);
  }

  mounted(): void {
    const scriptContentElement = document.querySelector(".script-content");
    const scriptElement = document.createElement("script");
    scriptElement.src = `${this.widgetIframeUrls[this.formData.environment].urlPrefix}/widget/build/script.js`;
    scriptContentElement.appendChild(scriptElement);

    scriptElement.addEventListener("load", () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.iaWidget.init(this.config);
    });
  }
}
