const initializeApofinderV1 = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "Apofinder";
  widgetContentElement.appendChild(widgetElement);

  (function(win, doc, tag, url, apoFunc, scriptElem) {
    win[apoFunc] = function (elementId, config, callback = null) {
      win.IhreApothekenApoFinderWidget = {
        config: config,
        elementId: elementId,
        apoFunc: apoFunc,
        callback: callback
      }
      scriptElem = doc.createElement(tag);
      scriptElem.async = 1;
      scriptElem.src = url;
      doc.body.appendChild(scriptElem);
    }
  })(window, document, "script", `${config.base.widgetBase}/widget/build/apofinder/snippet.min.js`, "initializeApofinderV1Widget");

  //eslint-disable-next-line
  initializeApofinderV1Widget("Apofinder", config, () => {
    console.log('test callback')
  });
};

const initializeApofinderV2 = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "Apofinder";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ApoFinderWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ApoFinderWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ApoFinderWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/apofinder-v2/snippet.min.js', "initializeApofinderV2Widget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeApofinderV2Widget("Apofinder", config, () => {
    console.log('test callback')
  });
};

const initializeManufacturer = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "Manufacturer";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ManufacturerWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ManufacturerWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ManufacturerWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/manufacturer-widget/snippet.min.js', "initializeManufacturerWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeManufacturerWidget("Manufacturer", config);
};

const initializeManufacturerShop = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "ManufacturerShop";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ManufacturerShopWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ManufacturerShopWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ManufacturerShopWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/manufacturer-shop-widget/snippet.min.js', "initializeManufacturerShopWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeManufacturerShopWidget("ManufacturerShop", config);
};

const initializeNetDoctor = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "NetDoctor";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.NetDoctorWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.NetDoctorWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.NetDoctorWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/net-doctor-widget/snippet.min.js', "initializeNetDoctorWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeNetDoctorWidget("NetDoctor", config);
};

const initializeNetDoctorIndicator = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "NetDoctorIndicator";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.NetDoctorIndicatorWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.NetDoctorIndicatorWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.NetDoctorIndicatorWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/net-doctor-indicator-widget/snippet.min.js', "initializeNetDoctorIndicatorWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeNetDoctorIndicatorWidget("NetDoctorIndicator", config);
};

const initializeNetDoctorWithPharmacy = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "NetDoctorWithPharmacy";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.NetDoctorWithPharmacyWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.NetDoctorWithPharmacyWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.NetDoctorWithPharmacyWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/net-doctor-with-pharmacy-widget/snippet.min.js', "initializeNetDoctorWithPharmacyWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeNetDoctorWithPharmacyWidget("NetDoctorWithPharmacy", config);
};

const initializeProductSearch = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "ProductSearch";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ProductSearchWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ProductSearchWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ProductSearchWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/product-search/snippet.min.js', "initializeProductSearchWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeProductSearchWidget("ProductSearch", config);
};

const initializeProductWithCategory = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "ProductWithCategory";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ProductWithCategoryWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ProductWithCategoryWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ProductWithCategoryWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/product-with-category/snippet.min.js', "initializeProductWithCategoryWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeProductWithCategoryWidget("ProductWithCategory", config);
};

const initializeShopping = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "Shopping";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.ShoppingWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.ShoppingWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.ShoppingWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/shopping-widget/snippet.min.js', "initializeShoppingWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeShoppingWidget("Shopping", config);
};

const initializeWirkenstoff = (config) => {
  const widgetContentElement = document.querySelector(".widget-content");
  widgetContentElement.innerHTML = "";

  const widgetElement = document.createElement("div");
  widgetElement.id = "Wirkenstoff";
  widgetContentElement.appendChild(widgetElement);

  (function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (win.WirkenstoffWidget == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit].push(function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new win.WirkenstoffWidget(elementId, config, callback);
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new win.WirkenstoffWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, 'script', config.base.widgetBase + '/widget/build/wirkenstoff-widget/snippet.min.js', "initializeWirkenstoffWidget");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.initializeWirkenstoffWidget("Wirkenstoff", config);
};

export {
  initializeApofinderV1,
  initializeApofinderV2,
  initializeManufacturer,
  initializeManufacturerShop,
  initializeNetDoctor,
  initializeProductSearch,
  initializeProductWithCategory,
  initializeShopping,
  initializeWirkenstoff,
  initializeNetDoctorIndicator,
  initializeNetDoctorWithPharmacy
}